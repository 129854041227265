import React, { useEffect } from 'react';
// import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
// import { Box } from '@material-ui/core';
import { FormaPago } from '@findep/mf-landings-core'
import { withThemeProps } from '@findep/microfronts-core';
import PropTypes from 'prop-types'

const FormapagoApp = (props) => {
// const [flujoState, setFlujoState] = useState({})
  const data = useStaticQuery(graphql`
  query {
      allComponentsJson(filter: {component: {eq: "plazos-app"}}) {
        edges {
          node {
            plazos {
              arrayDefault
              company
              producto
              arrayMeses {
                label
              }
              arrayQuincenas {
                label
              }
              arraySemanas {
                label
              }
              labelSemanal
              labelQuincenal
              labelMensual
              colorDefault
              lang
            }
          }
        }
      }
    }
`)


  let datosFPApp = props.configuration
  const [formaPago, setFormaPago] = React.useState(undefined)
  const [CF, setCF] = React.useState('')
    // console.log("datosFPApp",datosFPApp)
  useEffect(() => {
    let dataFlujo = data.allComponentsJson.edges[0].node.plazos.filter(item => item.company === props.company && item.lang===props.lang)
    // console.log("dataFlujo",dataFlujo)
    if (props.dedicacion) {
      // console.log("props=>",props)
      const producto = dataFlujo.find(item => item.producto === props.dedicacion)
      // console.log("producto =>",producto)
      datosFPApp.arraySemanas = producto.arraySemanas
      datosFPApp.arrayQuincenas = props.paymentMethod
      datosFPApp.arrayMeses = props.paymentMethod
      datosFPApp.arrayDefault =  producto.arrayDefault[0] === "arraySemanas" && producto.arraySemanas || producto.arrayDefault[0] === "arrayQuincenas" && props.paymentMethod || producto.arrayDefault[0] === "arrayMeses" && props.paymentMethod
      // datosFPApp.arrayDefault =  producto.arrayDefault[0] === "arraySemanas" && producto.arraySemanas || producto.arrayDefault[0] === "arrayQuincenas" && producto.arrayQuincenas || producto.arrayDefault[0] === "arrayMeses" && producto.arrayMeses
      datosFPApp.labelSemanal = producto.labelSemanal
      datosFPApp.labelQuincenal = producto.labelQuincenal
      datosFPApp.labelMensual = producto.labelMensual
      datosFPApp.colorDefault = producto.colorDefault 
    }
    // console.log("datosFPApp.arrayDefault[datosFPApp.arrayDefault?.length - 1].label=>",datosFPApp?.arrayDefault[datosFPApp.arrayDefault?.length - 1]?.label)
    let labelChar= datosFPApp?.arrayDefault[datosFPApp.arrayDefault?.length - 1]?.label=== undefined ? ' 130 catorcenal (60 meses)'  : datosFPApp?.arrayDefault[datosFPApp.arrayDefault?.length - 1]?.label
    setFormaPago(labelChar)
    setCF(datosFPApp.colorDefault)
  }, [props.dedicacion, datosFPApp])


  return (
    <FormaPago
      cf={CF}
      formaPago={formaPago}
      configuration={datosFPApp}
      onClick={
        (event) => {
          event = JSON.parse(event)
          props.handleChange('plazo', { value: event.value })
          props.handleChange('terms', { value: Number(event.extra.periodicidad) })
          props.handleChange('period', { value: event.extra.periodo })
        }
      }
      id="simulador-forma-pago"
      disabled={!props.dedicacion || !props.monto || !props.plazo || !props.pago}
    />
  );
}

export default withThemeProps(FormapagoApp, 'VDPNFormapagoApp')

FormapagoApp.propTypes = {
  lang: PropTypes.string
}

FormapagoApp.defaultProps = {
    lang:"es"
}