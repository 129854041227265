import React from 'react'

import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { useTheme } from '@findep/microfronts-core'

function PagoEstimado({ text, id }) {
    const { palette } = useTheme()
    const root = css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;        
        font-size: 26px;
        color: ${palette.secondary.main};        
    `

    return (
        <div css={root} id={id ? `${id}-div` : ''}>
            <strong>
                {text}
            </strong>
        </div>
    )
}

PagoEstimado.propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string,
};

export default PagoEstimado